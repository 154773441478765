import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import * as serviceWorker from './serviceWorker'

import Sketch from './page/Sketch'

import Head from './page/Head'
import SectionHead from './page/SectionHead'
import IntroText from './page/IntroText'
import Services from './page/Services'
import Customers from './page/Customers'
import Contact from './page/Contact'
import Footer from './page/Footer'

ReactDOM.render(
  <React.StrictMode>
    <div className="App">
      <Head />
      <IntroText />
      <Services/>
      <SectionHead title="Customers"/>
      <Customers />
      <SectionHead title="Contact"/>
      <Contact />
      <Footer />
      <Sketch />
    </div>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister()
