import React from 'react';
import './css/IntroText.css';

export default function IntroText(props) {
  return (
    <p className="intro_text">
      BARBAGALLO TECH is an independent software engineering company, with focus on web-based solutions for companies and private customers. With a wide span of competences from development to digital design BARBAGALLO TECH can realise software products through professional and personal communication with the customer.
    </p>
  )
}
