import React from 'react';
import './css/Contact.css';
import lukas from '../static/lukas.jpg';
import frederik from '../static/frederik_BW.png';

export default function Contact(props) {
  return (
    <div>
      <p className="contact_text">
        If you are interested in one or more of our services, or want to collaborate on a brand new project, feel free to contact us. We respond within 24 hours.
      </p>
      <div className="contacts">
        <div className="contact">
          <img className="contact_img" src={lukas} />
          <p>Lukas Barbagallo</p>
          <p>Computer Scientist from Copenhagen University</p>
          <p><a href="mailto:lukasbarbagallo@gmail.com">lukasbarbagallo@gmail.com</a></p>
          <p><a href="tel:+4531203102">+45 31 20 31 02</a></p>
        </div>
        <div className="contact">
          <img className="contact_img" src={frederik} />
          <p>Frederik Mølgaard</p>
          <p>Digital Designer from Aarhus University</p>
          <p><a href="mailto:ft.molgaarrd@gmail.com">ft.molgaarrd@gmail.com</a></p>
          <p><a href="tel:+4521465916">+45 21 46 59 16</a></p>
        </div>
      </div>
    </div>
  )
}



// Do you need want to create a new digital experience, or are you interested in collaborating on an existing project? Send us a message, we are always up for a conversation over coffee or tea.
