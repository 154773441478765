import React from 'react';
import SectionHead from '../page/SectionHead'
import './css/Services.css';

function Service(props) {
  return (
    <div style={{justifyContent:props.align}} className="service_outer">
      <p className="service">{props.text}</p>
    </div>
  )
}

function ThreeService(props) {
  return (
    <div className="all_services">
      <div className="three_services">
        <Service align="left" text={props.first} />
        <Service align="center" text={props.second} />
        <Service align="right" text={props.third} />
      </div>
    </div>
  )
}

function FourService(props) {
  return (
    <div className="all_services">
      <div className="four_services">
        <Service text={props.first} />
        <Service text={props.second} />
        <Service text={props.third} />
        <Service text={props.fourth} />
      </div>
    </div>
  )
}

export default function Services(props) {
  return (
    <div>
      <SectionHead title="Development for Multiple Mediums "/>
      <ThreeService
        first="Smartphone App Development"
        second="Web Applications Development"
        third="Application Software Development"
      />
      <SectionHead title="Types of development"/>
      <ThreeService
        first="Frontend & Graphical User Interface"
        second="Backend & Server Development"
        third="Server Architecture & Cloud Computing"
      />
      <SectionHead title="Design and UX"/>
      <ThreeService
        first="Concept Development"
        second="Graphic Design"
        third="UX Analysis and Testing"
      />
      <SectionHead title="Other services"/>
      <ThreeService
        first="IT-Consulting"
        second="Search Engine Optimization SEO"
        third="Digital Marketing"
      />
    </div>
  )
}
