import React from 'react';
import './css/Head.css';

export default function Head() {
  return (
    <div className="head">
      <p className="head_top">BARBAGALLO<br/>TECH</p>
      <p className="head_bot">BARBAGALLO<br/>TECH</p>
    </div>
  );
}
