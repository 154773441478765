import React, { Component } from "react";
import Sketch from "react-p5";

export default class App extends Component {

  setup = (p5, canvasParentRef) => {
    p5.createCanvas(window.innerWidth, window.innerHeight + 500);
    p5.stroke("#32502F");
    p5.noFill();
  }

  windowResized = p5 => {
    p5.resizeCanvas(window.innerWidth, window.innerHeight + 500);
  }

  draw = p5 => {
    p5.background("#292929");
    for (let i = 0; i < 1500; i += 100) {
      let ii = i/4

      let con_x_1 = - 600 - window.pageYOffset
      let con_y_1 =   400 + window.pageYOffset
      let con_x_2 = - 600 + window.pageYOffset
      let con_y_2 = - 100 + window.pageYOffset

      let fst_x_anc_1 = 0
      let fst_y_anc_1 = -300 + ii
      let fst_x_con_1 = fst_x_anc_1
      let fst_y_con_1 = fst_y_anc_1
      let snd_x_anc_1 = window.innerWidth/2 - 100
      let snd_y_anc_1 = 300 + ii
      let snd_x_con_1 = snd_x_anc_1 + con_x_1
      let snd_y_con_1 = snd_y_anc_1 + con_y_1

      let fst_x_anc_2 = snd_x_anc_1
      let fst_y_anc_2 = snd_y_anc_1
      let fst_x_con_2 = snd_x_anc_1 - con_x_1
      let fst_y_con_2 = snd_y_anc_1 - con_y_1
      let snd_x_anc_2 = window.innerWidth/2 + 100
      let snd_y_anc_2 = 700 + ii
      let snd_x_con_2 = snd_x_anc_2 + con_x_2
      let snd_y_con_2 = snd_y_anc_2 + con_y_2

      let fst_x_anc_3 = snd_x_anc_2
      let fst_y_anc_3 = snd_y_anc_2
      let fst_x_con_3 = snd_x_anc_2 - + con_x_2
      let fst_y_con_3 = snd_y_anc_2 - + con_y_2
      let snd_x_anc_3 = window.innerWidth
      let snd_y_anc_3 = 400 + ii
      let snd_x_con_3 = snd_x_anc_3
      let snd_y_con_3 = snd_y_anc_3
      p5.bezier(
        fst_x_anc_1,
        fst_y_anc_1,
        fst_x_con_1,
        fst_y_con_1,
        snd_x_con_1,
        snd_y_con_1,
        snd_x_anc_1,
        snd_y_anc_1,
      )

      p5.bezier(
        fst_x_anc_2,
        fst_y_anc_2,
        fst_x_con_2,
        fst_y_con_2,
        snd_x_con_2,
        snd_y_con_2,
        snd_x_anc_2,
        snd_y_anc_2,
      )

      p5.bezier(
        fst_x_anc_3,
        fst_y_anc_3,
        fst_x_con_3,
        fst_y_con_3,
        snd_x_con_3,
        snd_y_con_3,
        snd_x_anc_3,
        snd_y_anc_3,
      )
    }
  }
  render() {
    return <Sketch setup={this.setup} draw={this.draw} />
  }
}

// p5.bezier(
//   window.innerWidth,
//   i + 0 + 0.2, // slut
//   410, // slut hanle
//   i + 20,
//   i + 1000,
//   i + 400,
//   0 - i / 16.0,
//   0 + i / 8.0,
// );
// 1
// ---------
// fst_x_anc_1 = 0
// fst_y_anc_1 = -300 + i
// fst_x_con_1 = fst_x_anc_1
// fst_y_con_1 = fst_y_anc_1
// snd_x_con_1 = snd_y_anc_1 - 300
// snd_y_con_1 = snd_y_anc_1 + 200
// snd_x_anc_1 = window.innerWidth/2
// snd_y_anc_1 = 500 + i
//
// 2
// ---------
// fst_x_anc_2 = snd_x_anc_1
// fst_y_anc_2 = snd_y_anc_1
// fst_x_con_2 = snd_y_anc_1 + 300
// fst_y_con_2 = snd_y_anc_1 - 200
// snd_x_con_2 = snd_x_anc_2 - 200
// snd_y_con_2 = snd_y_anc_2 - 100
// snd_x_anc_2 = window.innerWidth/2 + 200
// snd_y_anc_2 = 900 + i
//
// 3
// ---------
// fst_x_anc_3 = snd_x_anc_2
// fst_y_anc_3 = snd_y_anc_2
// fst_x_con_3 = snd_x_anc_2 + 200
// fst_y_con_3 = snd_y_anc_2 + 100
// snd_x_con_3 = snd_x_anc_3
// snd_y_con_3 = snd_y_anc_3
// snd_x_anc_3 = window.innerWidth
// snd_y_anc_3 = 800 + i
//
// p5.bezier(
//   0, // x fst anc
//   -300 + i/2, // y fst anc
//   0, // x fst con
//   -300 + i/2, // y fst con
//   window.innerWidth/4, // x snd con
//   700+i, // y snd con
//   window.innerWidth/2, // x snd anc
//   500+i, // y snd anc
// )
// p5.bezier(
//   window.innerWidth, // x fst anc
//   300 + i/2, // y fst anc
//   window.innerWidth, // x fst con
//   400 + i/2, // y fst con
//   window.innerWidth - (window.innerWidth/4), // x snd con
//   300+i, // y snd con
//   window.innerWidth/2, // x snd anc
//   500+i, // y snd anc
// )
