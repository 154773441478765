import React from 'react';
import './css/Footer.css';

export default function Customers(props) {
  return (
    <p className="footer">
      © Barbagallo Tech - CVR 35076778
    </p>
  )
}
