import React from 'react';
import './css/Customers.css';
import dtd from '../static/dtd.png';
import tmc from '../static/tmc.png';
import koma from '../static/koma.png';
import cod from '../static/cod.png';
import bckc from '../static/bckc.png';
import alt from '../static/alt.png';
import gs from '../static/gs.png';
import dhl from '../static/dhl.png';
import avr from '../static/avr.png';

function Customer(props) {
  return (
    <div className="customer_outer">
      <img className="customer" src={props.src}/>
    </div>
  )
}

function Service(props) {
  return (
    <div className="service_outer">
      <p className="service" style={{color: 'white'}}>{props.text}</p>
    </div>
  )
}

export default function Customers(props) {
  return (
    <div className="all_customers">
      <div className="three_customers">
        <Customer src={alt} />
        <Customer src={dtd} />
        <Customer src={tmc} />
      </div>
      <div className="three_customers">
        <Customer src={cod} />
        <Customer src={koma} />
        <Customer src={bckc} />
      </div>
      <div className="three_customers">
        <Customer src={gs} />
        <Customer src={dhl} />
        <Customer src={avr} />
      </div>
    </div>
  )
}


// <div className="three_customers">
//   <Service text="Artist Niels Bonde" />
// </div>
